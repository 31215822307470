export default function License() {
	return (
		<div className="api-page">
			<div>
				<h2>full license text</h2>
				<p>{`Copyright ${new Date().getFullYear()} Jai Singh Tomar`}</p>
				<p>
					All images, assets and vectors published on minimal avatars can be
					used for free. You can use them for noncommercial and commercial
					purposes. You do not need to ask permission from or provide credit to
					the creator or minimal avatars. More precisely, minimal avatars grants
					you an nonexclusive, worldwide copyright license to download, copy,
					modify, distribute, perform, and use the assets provided from minimal
					avatars for free, including for commercial purposes, without
					permission from or attributing the creator or minimal avatars. This
					license does not include the right to compile assets, vectors or
					images from unDraw to replicate a similar or competing service, in any
					form or distribute the assets in packs or otherwise. This extends to
					automated and non-automated ways to link, embed, scrape, search or
					download the assets included on the website without our consent.
				</p>
			</div>
		</div>
	);
}
