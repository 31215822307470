import "./apipage.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useState } from "react";
import { Lottie } from "@crello/react-lottie";

// const host = "http://localhost:8000";
const host = "https://api.minimalavatars.com";

const canvasStyle = {
	width: "200px",
};

const codeExample1 = `<img src="${host}/avatar/random/png" />
<img src="${host}/avatar/random/png"/>
<img src="${host}/avatar/random/svg"/>
<img src="${host}/avatar/random/svg"/>
`;
const codeExample1_2 = `<img src="${host}/avatar/minimal/png" />
<img src="${host}/avatar/avatar/png"/>
<img src="${host}/avatar/is/svg"/>
<img src="${host}/avatar/awesome/svg"/>
`;
const codeExample2 = `<script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.10/lottie.min.js" integrity="sha512-MgjcOY0sw/iCwhkTOODJmQ4aEhXJVq3JXeIbm+aFo99qYx2hCyjmqnD+9tZSVYTXvvko7I82jQOEDoecdGeIIA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<div className="lottie"
data-animation-path="${host}/random-lottie">
</div>`;
const codeExample3 = `<script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.10/lottie.min.js" integrity="sha512-MgjcOY0sw/iCwhkTOODJmQ4aEhXJVq3JXeIbm+aFo99qYx2hCyjmqnD+9tZSVYTXvvko7I82jQOEDoecdGeIIA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
lottie.loadAnimation({
    container: element, 
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '${host}/random-lottie' 
  });`;

const codeExample4 = `<script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.10/lottie.min.js" integrity="sha512-MgjcOY0sw/iCwhkTOODJmQ4aEhXJVq3JXeIbm+aFo99qYx2hCyjmqnD+9tZSVYTXvvko7I82jQOEDoecdGeIIA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<div className="lottie"
data-animation-path="${host}/lottie/minimal">
</div>`;
const codeExample5 = `<script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.10/lottie.min.js" integrity="sha512-MgjcOY0sw/iCwhkTOODJmQ4aEhXJVq3JXeIbm+aFo99qYx2hCyjmqnD+9tZSVYTXvvko7I82jQOEDoecdGeIIA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
lottie.loadAnimation({
    container: element, 
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '${host}/lottie/minimal' 
  });`;

const APIpage = () => {
	const [lottieID, setLottieID] = useState("minimal");


	const lottieChangeHandler = (e) => {
		setLottieID(e.target.value);
	};

	return (
		<div className="api-page">
			<div id="static-random-avatar">
				<h3>{host + "/avatar/random/png"}</h3>
				<p>returns a random avatar in PNG format. Put SVG with PNG to get SVG file.</p>

				<div className="sample-container">
					<img
						className="medium-element"
						src={host + "/avatar/random/png"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/random/png"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/random/svg"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/random/svg"}
						alt=""
					/>
				</div>
				<div className="code-examples">
					<div className="code-box">
						<SyntaxHighlighter language="HTML" style={vs2015}>
							{codeExample1}
						</SyntaxHighlighter>
					</div>
				</div>
			</div>

			<div id="static-value-avatar">
				<h3>{host + "/avatar/<name>/png"}</h3>
				<p>
					returns a specific avatar based on name value in the URl. Can return
					either PNG or SVG.
				</p>

				<div className="sample-container">
					<img
						className="medium-element"
						src={host + "/avatar/minimal/png"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/avatar/png"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/is/svg"}
						alt=""
					/>
					<img
						className="medium-element"
						src={host + "/avatar/awesome/svg"}
						alt=""
					/>
				</div>
				<div className="code-examples">
					<div className="code-box">
						<SyntaxHighlighter language="HTML" style={vs2015}>
							{codeExample1_2}
						</SyntaxHighlighter>
					</div>
				</div>
			</div>

			<div id="random-lottie">
				<h3>{host + "/random-lottie"}</h3>
				<p>returns a random animated lottie avatar on every request</p>
				<div className="sample-container">
					<div className="medium-element">
						<Lottie
							config={{
								path: host + "/random-lottie/",
								loop: true,
								autoplay: true,
							}}
							style={canvasStyle}
						/>
					</div>
					<div className="medium-element">
						<Lottie
							config={{
								path: host + "/random-lottie/",
								loop: true,
								autoplay: true,
							}}
							style={canvasStyle}
						/>
					</div>
					<div className="medium-element">
						<Lottie
							config={{
								path: host + "/random-lottie/",
								loop: true,
								autoplay: true,
							}}
							style={canvasStyle}
						/>
					</div>
					<div className="medium-element">
						<Lottie
							config={{
								path: host + "/random-lottie/",
								loop: true,
								autoplay: true,
							}}
							style={canvasStyle}
						/>
					</div>
				</div>
				<div className="code-examples">
					<div className="code-box">
						<SyntaxHighlighter language="HTML" style={vs2015}>
							{codeExample2}
						</SyntaxHighlighter>
					</div>

					<div className="code-box">
						<SyntaxHighlighter language="javascript" style={vs2015}>
							{codeExample3}
						</SyntaxHighlighter>
					</div>
				</div>
			</div>
			<div id="lottie-by-name">
				<h3>{host + "/lottie/<name>"}</h3>
				<p>
					returns a specific avatar based on name value. it could be any name or
					number.
				</p>

				<div className="sample-container">
					<div>
						<div className="medium-element">
							<Lottie
								config={{
									path: host + "/lottie/" + lottieID,
									loop: true,
									autoplay: true,
								}}
								style={canvasStyle}
							/>
						</div>

						<label>
							{host}/lottie/
							<input
								type="text"
								size="10"
								value={lottieID}
								onChange={lottieChangeHandler}
							/>
						</label>
					</div>
				</div>
				<div className="code-examples">
					<div className="code-box">
						<SyntaxHighlighter language="HTML" style={vs2015}>
							{codeExample4}
						</SyntaxHighlighter>
					</div>

					<div className="code-box">
						<SyntaxHighlighter language="javascript" style={vs2015}>
							{codeExample5}
						</SyntaxHighlighter>
					</div>
				</div>
			</div>
		</div>
	);
};

export default APIpage;
