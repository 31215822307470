import { Lottie } from "@crello/react-lottie";

const lottiePath = "https://minimal.adaptable.app/random-lottie"
// const lottiePath = "http://localhost:8000/random-lottie"

const Homepage = () => {
	return (
		<div className="homepage-container">
			<div className="landing-container">
				<div className="landing-text">
					<h1>Create minimal, unique and playful avatars for free</h1>
                    <a href="/static" className="primary-btn remove-style">Create static avatar</a>
					<a href="/animated" className="secondary-btn remove-style">Create animated avatar</a>
				</div>
				<div className="landing-lottie">
					<Lottie config={{ path: lottiePath,loop:true }}></Lottie>
				</div>
			</div>
            <div className="highlights-container">
               
                <div className="highlight pink">
                    <div className="img-container">
                        
                    <Lottie config={{ path: "createorrandom.json",loop:true }}></Lottie>
                    </div>
                    <p>
                    Create Your Own Avatar,
                    or Generate a Random One!
                    </p>
                </div>
                <div className="highlight purple">
                    <div className="img-container">
                        
                    <Lottie config={{ path: "customizable.json",loop:true }}></Lottie>
                    </div>
                    <p>
                    Fully Customizable Lottie  Animations
                    </p>
                </div>        
                <div className="highlight dark-blue">
                    <div className="img-container">
                        
                    <img src="logo192.png" alt="" />
                    </div>
                    <p>
                    Default avatars for your products,
                    using our API
                    </p>
                </div>        
                <div className="highlight blue">
                    <div className="img-container">
                        
                    <img src="color-palette.png" alt="" />
                    </div>
                    <p>
                    In-built Color Palettes
                    to choose from
                    </p>
                </div>        
                <div className="highlight light-blue">
                    <div className="img-container">
                        
                    <img src="svg@4x.png" alt="" />
                    </div>
                    <p>
                    Get SVG/PNG for Static Avatars
                    </p>
                </div>        
                <div className="highlight green">
                    <div className="img-container">
                        
                    <img src="json.png" alt="" />
                    </div>
                    <p>
                    Get Lottie JSON or directly use our URL
                    </p>
                </div>
            </div>
		</div>
	);
};

export default Homepage;
