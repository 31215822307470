import { Lottie } from "@crello/react-lottie";
import { useEffect, useState, useRef, memo } from "react";
import tinycolor from "tinycolor2";
import ItemsCarousel from "react-items-carousel";
import { titleCase } from "title-case";

function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16) / 255,
				g: parseInt(result[2], 16) / 255,
				b: parseInt(result[3], 16) / 255,
		  }
		: null;
}
const changeColor = (obj, color) => {
	const rgb = hexToRgb(color);

	obj.layers.forEach((layer, index, theArray) => {
		layer.shapes.forEach((shape, index, arr) => {
			shape.it.forEach((item, index, arr) => {
				if (item.ty === "fl" || item.ty === "st") {
					arr[index].c.k = [rgb.r, rgb.g, rgb.b];
				}
			});
		});
	});
};
const ElementBox = ({
	data,
	urlParams,
	keyName,
    changeAvatar,
    storeColor,
    setLottieColors
}) => {
	const [activeItemIndex, setActiveItemIndex] = useState(0);

	const chevronWidth = 20;

	useEffect(() => {
		document.querySelectorAll(".options svg").forEach((input) => {
			var bbox = input.getBBox();
			var viewBox = [
				bbox.x - 10,
				bbox.y - 10,
				bbox.width + 20,
				bbox.height + 20,
			].join(" ");
			input.setAttribute("viewBox", viewBox);
		});
	}, [urlParams, changeAvatar, activeItemIndex]);

	const dataHTML = data.map((item, ind) => {
		var color = urlParams[keyName + "Color"];
		var itemColor;
		if (tinycolor(color).isDark()) {
			itemColor = tinycolor(color).lighten(40).toString();
		} else {
			itemColor = tinycolor(color).darken(40).toString();
		}
		changeColor(item.json, itemColor);
		var opt = {
			animationData: item.json,
			autoplay: false,
			loop: true,
		};
		return (
			<div key={ind + Math.random()} className="options">
				<button
					onClick={(e) => changeAvatar(keyName, item.id)}
					style={{ backgroundColor: color }}
				>
					<Lottie config={opt} height={40} width={40} />
				</button>
			</div>
		);
	});

	return (
        <>
		<div>

        <div className="title-color-container">

        <h3>{titleCase(keyName)}</h3>
        {/* <input type="color"   onChange={(e)=>storeColor(keyName+"Color",e)} />
        <button onClick={setLottieColors}>set</button> */}
        </div>
        <div className="scroll-container">
						<ItemsCarousel
							requestToChangeActive={setActiveItemIndex}
							activeItemIndex={activeItemIndex}
							numberOfCards={5}
							gutter={5}
							rightChevron={<button className="chevron chevron-right">&gt;</button>}
							leftChevron={<button className="chevron chevron-left">&lt;</button>}
							insideChevron
                            firstAndLastGutter
							showSlither={false}
							chevronWidth={chevronWidth}
							className="color-palette-container"
						>
			{dataHTML}
            </ItemsCarousel>
            </div>
		</div>

            </>
	);
};

export default memo(ElementBox,(prev,next)=>{
	return prev.urlParams===next.urlParams
})
