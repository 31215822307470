import "./App.css"

function Header() {
    
    return(
        <header>
			<nav>
				<a href="/"><h3>minimal avatars</h3></a>
				<div className="cover-text">

				<a href="/static">
					<h3>Static Avatars</h3>
				</a>
				<a href="/animated">
					<h3>Animated Avatars</h3>
				</a>
				{/* <a href="/api">
					<h3>API</h3>
				</a> */}

				</div>
			</nav>
		</header>
    )
}

export default Header