import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import { Lottie } from "@crello/react-lottie";

import ColorList from "./colorlist";
import StaticElementList from "./staticelementlist";
import tinycolor from "tinycolor2";

const keyToIndex = (key) => {
	var idx;
	switch (key) {
		case "base":
			idx = 0;
			break;
		case "hair":
			idx = 1;
			break;
		case "eyes":
			idx = 2;
			break;
		case "mouth":
			idx = 3;
			break;
		default:
			idx = 0;
			break;
	}
	return idx;
};

// const fetchURL = "http://localhost:8000";
const fetchURL = "https://minimal.adaptable.app/"

function StaticBuilder() {
	const [data, setData] = useState();
	const [canvas, setCanvas] = useState();
	const [colors, setColors] = useState([]);
	const [canvasColors, setCanvasColors] = useState();
	const lottieContainer = useRef(null);
	const [downloadSize, setDownloadSize] = useState(500);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const canvasEl = new fabric.StaticCanvas("c", {
			height: 500,
			width: 500,
		});
		setIsLoading(true);

		fetch(fetchURL + "/static-data").then((response) =>
			response.json().then((json) => {
				setData(json.items);
				setColors(json.colors);
				setCanvasColors(getColorsFromCanvas(json.canvas));
				canvasEl.loadFromJSON(json.canvas);
				setCanvas(canvasEl);
				resizeCanvas(canvasEl);
				initPalette(getColorsFromCanvas(json.canvas));
				setIsLoading(false);
			})
		);
	}, []);
	useEffect(() => {
		document.querySelectorAll(".color-input").forEach((item) => {
			item.addEventListener("input", (e) => {
				if (canvas) {
					const color = e.target.value;
					const id = e.target.id;
					switch (id) {
						case "base":
							setColor(color, canvas.getObjects()[0]);
							document
								.querySelectorAll("div#base svg")
								.forEach((svg, index) => {
									if (svg) {
										svgColorSet(svg, color);
									}
								});
							break;
						case "hair":
							setColor(color, canvas.getObjects()[1]);
							document
								.querySelectorAll("div#hair svg")
								.forEach((svg, index) => {
									if (svg) {
										svgColorSet(svg, color);
									}
								});
							break;
						case "eyes":
							setColor(color, canvas.getObjects()[2]);
							document
								.querySelectorAll("div#eyes svg")
								.forEach((svg, index) => {
									if (svg) {
										svgColorSet(svg, color);
									}
								});
							break;
						case "mouth":
							setColor(color, canvas.getObjects()[3]);
							document
								.querySelectorAll("div#mouth svg")
								.forEach((svg, index) => {
									if (svg) {
										svgColorSet(svg, color);
									}
								});
							break;
						default:
							break;
					}
				}
			});
		});
	}, []);
	const getColorsFromCanvas = (docs) => {
		return docs.objects.map((item) => {
			if (item.type === "group") {
				return item.objects[0].fill.toString();
			} else {
				return item.fill.toString();
			}
		});
	};
	const resizeCanvas = (canvasCopy) => {
		const outerCanvasContainer = document.getElementsByClassName(
			"main-lottie-container"
		)[0];
		const ratio = canvasCopy.getWidth() / canvasCopy.getHeight();
		const containerWidth = outerCanvasContainer.clientWidth;
		const scale = containerWidth / canvasCopy.getWidth();
		const zoom = canvasCopy.getZoom() * scale;
		canvasCopy.setDimensions({
			width: containerWidth,
			height: containerWidth / ratio,
		});
		canvasCopy.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
	};

	const changePalette = (color) => {
		var arr = canvas.getObjects();
		let i = 0;
		for (const key in color) {
			setColor(color[key], arr[i]);
			// $(".color-input")[i].value = color[key];
			i++;
		}
		document.querySelectorAll("#base svg").forEach((index, item) => {
			svgColorSet(index, color.baseColor);
		});
		document.querySelectorAll("#hair svg").forEach((index, item) => {
			svgColorSet(index, color.hairColor);
		});
		document.querySelectorAll("#eyes svg").forEach((index, item) => {
			svgColorSet(index, color.eyesColor);
		});
		document.querySelectorAll("#mouth svg").forEach((index, item) => {
			svgColorSet(index, color.mouthColor);
		});

		document.querySelectorAll("input#base").forEach((item) => {
			item.value = color.baseColor;
		});
		document.querySelectorAll("input#hair").forEach((item) => {
			item.value = color.hairColor;
		});
		document.querySelectorAll("input#eyes").forEach((item) => {
			item.value = color.eyesColor;
		});
		document.querySelectorAll("input#mouth").forEach((item) => {
			item.value = color.mouthColor;
		});
	};

	const initPalette = (colorArr) => {
		document.querySelectorAll("#base svg").forEach((index, item) => {
			svgColorSet(index, colorArr[0].toString());
		});
		document.querySelectorAll("#hair svg").forEach((index, item) => {
			svgColorSet(index, colorArr[1].toString());
		});
		document.querySelectorAll("#eyes svg").forEach((index, item) => {
			svgColorSet(index, colorArr[2].toString());
		});
		document.querySelectorAll("#mouth svg").forEach((index, item) => {
			svgColorSet(index, colorArr[3].toString());
		});
		document.querySelectorAll("input#base").forEach((item) => {
			item.value = colorArr[0];
		});
		document.querySelectorAll("input#hair").forEach((item) => {
			item.value = colorArr[1];
		});
		document.querySelectorAll("input#eyes").forEach((item) => {
			item.value = colorArr[2];
		});
		document.querySelectorAll("input#mouth").forEach((item) => {
			item.value = colorArr[3];
		});
	};
	const setColor = (color, element) => {
		var el = element;
		if (el.type === "group") {
			el._objects.forEach((item) => {
				item.set({
					fill: color,
					stroke: color,
				});
			});
		} else {
			el.set({
				fill: color,
				stroke: color,
			});
		}

		canvas.renderAll();
	};
	const svgColorSet = (svg, color) => {
		var itemColor;
		if (tinycolor(color).isDark()) {
			itemColor = tinycolor(color.toString()).lighten(40).toString();
		} else if (tinycolor(color).isLight()) {
			itemColor = tinycolor(color.toString()).darken(40).toString();
		}
		svg.style.background = color;
		svg.childNodes.forEach((item) => {
			item.childNodes.forEach((el) => {
				el.style.fill = itemColor;
			});
		});
	};

	const randomizer = () => {
		Object.keys(data).forEach((key) => {
			const randomObj =
				data[key][Math.floor(Math.random() * data[key].length) + 1];
			if (randomObj) {
				replaceElement(randomObj.toString(), key);
			}
		});

		const randomColor = colors[Math.floor(Math.random() * colors.length + 1)];
		if (randomColor) {
			changePalette(randomColor);
		}
	};
	const replaceElement = (item, key) => {
		var idx = keyToIndex(key);
		var oldObj = canvas.item(idx);
		console.log(oldObj);
		fabric.loadSVGFromString(
			item,
			(objects, options) => {
				var newObj = fabric.util.groupSVGElements(objects, {
					padding: 0,
					lockMovementX: true,
					lockMovementY: true,
				});
				newObj.top = newObj.top + 20;
				newObj.lockMovementX = true;
				newObj.lockMovementY = true;

				newObj.lockScalingX = true;
				newObj.lockScalingY = true;
				newObj.lockRotation = true;
				canvas.remove(oldObj);
				canvas.insertAt(newObj, idx);
			},
			function (item, object) {
				var fill;
				if (oldObj.type === "group") {
					fill = oldObj._objects[0].fill;
				} else {
					fill = oldObj.fill;
				}
				object.set("fill", fill);
				object.set("stroke", fill);
			}
		);
	};

	const onInputColorChange = (event, keyName) => {
		setColor(event.target.value, canvas.item(keyToIndex(keyName)));
	};

	const downloadPng = (e) => {
		const domCanvas = document.createElement("canvas");
		domCanvas.id = "new";
		const newCanvas = new fabric.StaticCanvas("new", {
			preserveObjectStacking: true,
			width: 500,
			height: 500,
		});
		newCanvas.loadFromJSON(canvas.toJSON());

		const ratio = newCanvas.getWidth() / newCanvas.getHeight();
		const containerWidth = downloadSize;

		const scale = containerWidth / canvas.getWidth();
		const zoom = canvas.getZoom() * scale;
		newCanvas.setDimensions({
			width: containerWidth,
			height: containerWidth / ratio,
		});
		newCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
		domCanvas.remove();

		e.target.href = newCanvas.toDataURL({
			format: "png",
		});
	};
	const downloadSvg = (e) => {
		const domCanvas = document.createElement("canvas");
		domCanvas.id = "new";
		const newCanvas = new fabric.StaticCanvas("new", {
			preserveObjectStacking: true,
			width: 500,
			height: 500,
		});
		newCanvas.loadFromJSON(canvas.toJSON());

		const ratio = newCanvas.getWidth() / newCanvas.getHeight();
		const containerWidth = downloadSize;

		const scale = containerWidth / canvas.getWidth();
		const zoom = canvas.getZoom() * scale;
		newCanvas.setDimensions({
			width: containerWidth,
			height: containerWidth / ratio,
		});
		newCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
		domCanvas.remove();

		var svg = newCanvas.toSVG();
		var svgBlob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });

		e.target.href = URL.createObjectURL(svgBlob);
	};
	const toggleDownloadSelect = (e, size) => {
		document.querySelectorAll(".download-options button").forEach((item) => {
			console.log(item);
			item.classList.remove("selected-btn");
		});
		e.target.classList.toggle("selected-btn");
		setDownloadSize(size);
	};
	return (
		<div className="builder">
			{isLoading && (
				<div className="loading-container">
					<Lottie
						config={{ path: "loading.json", autoplay: true, loop: true }}
						className="loading-lottie"
						width="200px"
					/>
				</div>
			)}
			<div className="main-panel">
				<ColorList colors={colors} changePalette={changePalette} />
				<div className="tools">
					<div className="main-lottie-container" ref={lottieContainer}>
						<div className="download-btns"></div>

						<div className="outline">
							<canvas id="c"></canvas>
						</div>
						<div className="color-input bottom-menu-container">
							<div className="colors-input-container">
								<input
									id="base"
									type="color"
									onChange={(e) => onInputColorChange(e, "base")}
								/>
								<input
									id="hair"
									type="color"
									onChange={(e) => onInputColorChange(e, "hair")}
								/>
								<input
									type="color"
									id="eyes"
									onChange={(e) => onInputColorChange(e, "eyes")}
								/>

								<input
									id="mouth"
									type="color"
									onChange={(e) => onInputColorChange(e, "mouth")}
								/>
							</div>

							<button className="secondary-btn" onClick={randomizer}>
								Randomize
							</button>
						</div>
						<div className="download-options">
							<button onClick={(e) => toggleDownloadSelect(e, 100)}>
								{" "}
								100x100
							</button>
							<button onClick={(e) => toggleDownloadSelect(e, 250)}>
								{" "}
								250x250
							</button>
							<button onClick={(e) => toggleDownloadSelect(e, 500)}>
								{" "}
								500x500
							</button>
							<button onClick={(e) => toggleDownloadSelect(e, 1000)}>
								{" "}
								1000x1000
							</button>
						</div>
						<div className="download-btns">
							<a href="" download="minimal-avatars.png" onClick={downloadPng}>
								Download PNG
							</a>
							<a href="" download="minimal-avatars.svg" onClick={downloadSvg}>
								Download SVG
							</a>
						</div>
					</div>
					{data && (
						<StaticElementList
							data={data}
							replaceElement={replaceElement}
							canvasColors={canvasColors}
							onInputColorChange={onInputColorChange}
						/>
					)}
				</div>
			</div>
			<br />
			<br />
			<br />
			<br />
		</div>
	);
}

export default StaticBuilder;
