
import ElementBox from "./elementbox.js";

const ElementBlock = ({
	data,
	urlParams,
	changeAvatar,
	storeColor,
	setLottieColors,
}) => {
	const dataHTML = Object.keys(data).map((keyName, index) => {
		return (
				<ElementBox
					key={index}
					data={data[keyName]}
					urlParams={urlParams}
					changeAvatar={changeAvatar}
					keyName={keyName}
                    storeColor={storeColor}
                    setLottieColors={setLottieColors}
				/>
		);
	});
	return <div className="objects-container">{dataHTML}</div>;
};

export default ElementBlock
