import { useParams} from "react-router-dom";

import { Lottie } from '@crello/react-lottie'


function RandomAnimatedAvatar() {

    let { name } = useParams();
    let options = {
		path: "http://localhost:8000/api/" + name,
		loop: true,
		autoplay:true
	}
    console.log(name);
	return (
		<div className="App">
			<div className="main-lottie-container">
			<Lottie config={options}  />
			</div>
		</div>
	);
}

export default RandomAnimatedAvatar;

