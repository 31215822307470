import React, { useState, useRef } from "react";
import ItemsCarousel from "react-items-carousel";
import { titleCase } from "title-case";

function StaticElementList({
	data,
	replaceElement,
	canvasColors,
	onInputColorChange,
}) {
	const [activeItemIndex, setActiveItemIndex] = useState([0, 0, 0, 0]);
	const elementRef = useRef(null);

	const chevronWidth = 40;



	const fitSVGs = () => {
		document.querySelectorAll(".options svg").forEach((input) => {
			var bbox = input.getBBox();
			var viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
			input.setAttribute("viewBox", viewBox);
		});
	};
	const staticTools = Object.keys(data).map((key, index) => {
		fitSVGs();
		return (
			<>
			<div>

					<div className="title-color-container">
						<h3>{titleCase(key)}</h3>
						<div className="color-input ">

						{/* <input
							type="color"
							id={key}
							onChange={(e) => onInputColorChange(e, key)}
							className=""
							/> */}
						</div>
					</div>
					<div className="scroll-container">
						<ItemsCarousel
							requestToChangeActive={(e) => {
								const arr = [...activeItemIndex];
								arr[index] = e;
								setActiveItemIndex(arr);
							}}
							activeItemIndex={activeItemIndex[index]}
							numberOfCards={5}
							gutter={10}
							rightChevron={<button className="chevron chevron-right">&gt;</button>}
							leftChevron={<button className="chevron chevron-left">&lt;</button>}
							insideChevron
                            firstAndLastGutter

							chevronWidth={chevronWidth}
						>
							{data[key].map((item, index2) => {
								return (
									<div key={index2} id={key} className="options static-options">
										<button onClick={(e) => replaceElement(item, key)}>
											<svg
												dangerouslySetInnerHTML={{ __html: item }}
												style={{ width: 30, height: 30 }}
											></svg>
										</button>
									</div>
								);
							})}
						</ItemsCarousel>
					</div>
			</div>

			</>
		);
	});

	return (
		<div className="objects-container" ref={elementRef}>
			{staticTools}
		</div>
	);
}

export default StaticElementList;
