import React, { useState, useEffect } from "react";

import ColorList from "./colorlist";
import { Lottie } from "@crello/react-lottie";
import ElementBlock from "./elementblock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

// const apiURL = "http://localhost:8000/lottie?";
// const fetchUrl = "http://localhost:8000/";
const apiURL = "https://minimal.adaptable.app/lottie?";
const fetchUrl = "https://minimal.adaptable.app/";
const defaultParams = {
	base: 0,
	eyes: 1,
	hair: 0,
	mouth: 9,
	baseColor: "#F267A0",
	hairColor: "#F6BDE8",
	eyesColor: "#B8F1B5",
	mouthColor: "#B8F1B5",
};

function AnimatedBuilder() {
	const [data, setData] = useState();
	const [urlParams, setUrlParams] = useState(defaultParams);
	const [options, setOptions] = useState({
		path: apiURL + new URLSearchParams(defaultParams),
		loop: true,
		autoplay: true,
	});
	const [colorOptions, setColorOptions] = useState({
		baseColor: "#F267A0",
		hairColor: "#F6BDE8",
		eyesColor: "#B8F1B5",
		mouthColor: "#B8F1B5",
	});
	const [isLoading, setIsLoading] = useState(true);
	const [colors, setColors] = useState([]);
	useEffect(() => {
		setIsLoading(true);
		fetch(fetchUrl + "item-data").then((Response) => {
			Response.json().then((json) => {
				setData(json);
				document.querySelectorAll(".options svg").forEach((input) => {
					var bbox = input.getBBox();
					var viewBox = [
						bbox.x - 10,
						bbox.y - 10,
						bbox.width + 20,
						bbox.height + 20,
					].join(" ");
					input.setAttribute("viewBox", viewBox);
					setIsLoading(false);
				});
			});
		});

		fetch(fetchUrl + "colors").then((Response) => {
			Response.json().then((colorList) => {
				setColors(colorList);
			});
		});
	}, []);

	useEffect(() => {
		document.querySelectorAll(".color-input input").forEach((item) => {
			item.value = urlParams[item.id];
		});
	}, [urlParams]);
	const changeAvatar = (key, id) => {
		const obj = urlParams;
		obj[key] = id;
		setUrlParams(obj);
		setOptions({
			...options,
			path: apiURL + new URLSearchParams(obj),
		});
	};

	const storeColor = (object, color) => {
		const obj = { ...colorOptions };
		obj[object] = color.target.value;
		setColorOptions(obj);
	};

	const blockChangeColor = (color) => {
		const obj = Object.assign({}, urlParams);
		obj["baseColor"] = color.hex;
		setUrlParams(obj);
	};

	const changePalette = (palette) => {
		const obj = { ...urlParams, ...palette };
		setColorOptions({ ...palette });
		setUrlParams(obj);
		setOptions({
			...options,
			path: apiURL + new URLSearchParams({ ...obj, ...palette }),
		});
	};
	const setLottieColors = () => {
		const obj = { ...urlParams, ...colorOptions };
		setUrlParams(obj);
		setOptions({
			...options,
			path: apiURL + new URLSearchParams(obj),
		});
	};
	const getJSON = (e) => {
		fetch(options.path)
			.then((res) => {
				return res.blob();
			})
			.then((blob) => {
				const href = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = href;
				link.setAttribute("download", "avatar.json"); //or any other extension
				document.body.appendChild(link);
				link.click();
			});
	};

	const copyURLtoClipboard = () => {
		navigator.clipboard.writeText(options.path);
	};

	const randomizer = () => {
		const randomObj = {};
		Object.keys(data).forEach((key) => {
			randomObj[key] = Math.floor(Math.random() * data[key].length);
		});
		const randomColor = colors[Math.floor(Math.random() * colors.length + 1)];
		if (randomColor) {
			setColorOptions(randomColor)
			setUrlParams({ ...randomObj, ...randomColor });
			setOptions({
				...options,
				path: apiURL + new URLSearchParams({ ...randomObj, ...randomColor }),
			});
		}
	};
	return (
		<div className="builder"> 
			{isLoading && (
				<div className="loading-container">
					<Lottie
						config={{ path: "loading.json", autoplay: true, loop: true }}
						className="loading-lottie"
						width="200px"
					/>
				</div>
			)}
			<div className="main-panel">
				<ColorList colors={colors} changePalette={changePalette} />

				<div className="tools">
					<div className="main-lottie-container">
						
						<div className="outline">
							<Lottie
								config={options}
								className="lottie"
							/>
						</div>
						<div className="color-input bottom-menu-container">
							<div className="colors-input-container">

							<input
								id="baseColor"
								type="color"
								value={urlParams.baseColor}
								onChange={(e) => storeColor("baseColor", e)}
							/>
							<input
								id="hairColor"
								type="color"
								value={urlParams.hairColor}
								onChange={(e) => storeColor("hairColor", e)}
							/>
							<input
								type="color"
								id="eyesColor"
								value={urlParams.eyesColor}
								onChange={(e) => storeColor("eyesColor", e)}
							/>
							<input
								id="mouthColor"
								type="color"
								value={urlParams.mouthColor}
								onChange={(e) => storeColor("mouthColor", e)}
							/>
							</div>

							<button onClick={setLottieColors} className="tertiary-btn">
								Set Color
							</button>
							<button onClick={randomizer} className="secondary-btn">
								Randomize
							</button>
						</div>
						<div className="lottie-btns ">
							<button className="primary-btn" onClick={getJSON}>
								Download Lottie JSON
							</button>
							<div className="copy-clipboard">
								<input type="text" value={options.path} readOnly  />
								<button className="copy-font" onClick={copyURLtoClipboard}>
									<FontAwesomeIcon icon={faCopy} />
								</button>
							</div>
						</div>
					</div>
					{data && (
						<ElementBlock
							data={data}
							urlParams={urlParams}
							changeAvatar={changeAvatar}
							storeColor={storeColor}
							setLottieColors={setLottieColors}
						/>
					)}
				</div>
			</div>
			<br />
			<br />
			<br />
			<br />
		</div>
	);
}

export default AnimatedBuilder;
