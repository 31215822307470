import { useState } from "react";
import ItemsCarousel from "react-items-carousel";

const ColorList = ({colors,changePalette}) => {
	const [activeItemIndex, setActiveItemIndex] = useState(0);

	const chevronWidth = 40;

    const colorHTML = colors.map((element,index)=>{
        const size = {
            width : "50px"
        }
        const base = {backgroundColor:element.baseColor}
        const eyes = {backgroundColor:element.eyesColor}
        const hair = {backgroundColor:element.hairColor}
        const mouth = {backgroundColor:element.mouthColor}

        return(
            <div key={index} className="color-list" onClick={()=>changePalette(element)}>
                <div style={{...size,...base}}></div>
                <div style={{...size,...hair}}></div>
                <div style={{...size,...eyes}}></div>
                <div style={{...size,...mouth}}></div>

            </div>
        )
    })

	return(
		<div className="colors-container">
            <ItemsCarousel
							requestToChangeActive={
								setActiveItemIndex
							}
							activeItemIndex={activeItemIndex}
							numberOfCards={10}
							gutter={10}
							rightChevron={<button className="chevron chevron-right">&gt;</button>}
							leftChevron={<button className="chevron chevron-left">&lt;</button>}
							insideChevron
                            alwaysShowChevrons
                            firstAndLastGutter
							chevronWidth={chevronWidth}
						>
			{colorHTML}
            </ItemsCarousel>
		</div>
        
	)
}

export default ColorList