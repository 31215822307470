export default function Contact() {
	return (
		<div className="api-page">
			<div>
				<p>for any feedbacks or queries, you can reach out to me :</p>
				<p><a href="mailto:jaytomar26+minimal@gmail.com">email</a></p>
				<p><a href="https://twitter.com/jai_to_mars">twitter</a></p>


			</div>
		</div>
	);
}
