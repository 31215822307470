import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import AnimatedBuilder from "./animatedbuilder";
import RandomAnimatedAvatar from "./randomanimatedavatar";
import Header from "./header"
import APIpage from "./apipage"
import StaticBuilder from "./staticbuilder";
import Homepage from "./homepage"
import License from "./license"
import Footer from "./footer"
import Contact from "./contact"


function App() {
	return (
		<div className="App">
				<Header/>

			<Router>
				<Route exact path="/">
					<Homepage/>
				</Route>
				<Route  exact path="/static">
					<StaticBuilder />
				</Route>
				<Route  exact path="/animated">
					<AnimatedBuilder />
				</Route>
				<Route path="/random/:name">
					<RandomAnimatedAvatar />
				</Route>
				<Route path="/api">
					<APIpage/>
				</Route>
				<Route exact path="/license">
					<License/>
				</Route>
				<Route exact path="/contact">
					<Contact/>
				</Route>
			</Router>
			<Footer/>
		</div>
	);
}

export default App;
